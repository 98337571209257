import React, { useEffect, useState } from "react"
import AOS from "aos"
import "aos/dist/aos.css"
import { StaticImage } from "gatsby-plugin-image"
import addToMailchimp from "gatsby-plugin-mailchimp"
import {
  Seo,
  Layout,
  Hero,
  ErrorBoundary,
  MindBodyPage,
  Banner,
  BlogForm,
} from "../../index.js"
import { graphql, Link } from "gatsby"
import "../style/_blog-template.scss"
import HeroImage from "../images/blog-post-hero.webp"
import cta1 from "../images/blog-image1.webp"

const BlogPost = ({ data, pageContext }) => {
  const post = data?.wpPost
  const blockJson = JSON.parse(data?.wpPost?.blocksJSON)
  const formData = data?.wpBlogLanding?.blocks[0]?.formBlock
  useEffect(() => {
    AOS.init()
    AOS.refresh()
  }, [])

  // handle toggle form
  const [toggle, setToggle] = useState(false)
  const onClick = () => {
    const healCode = document.querySelector("healcode-widget")
    if (healCode) {
      healCode.style.display = toggle ? "none" : "block"
    }
    setToggle(!toggle)
  }

  // state for mail chip
  const [state, setState] = useState({
    result: "",
    email: "",
    message: "",
    toast: false,
  })
  const handleInputChange = event => {
    const value = event.target.value
    setState({ email: value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(state.email)
    setState({
      ...state,
      message: result.msg,
      result: result.result,
      toast: true,
    })
    setTimeout(() => {
      setState({ ...state, toast: false })
    }, 4000)
  }

  return (
    <Layout myProp={pageContext.type}>
      <Seo
        title={post.seo?.title}
        description={post.seo?.metaDesc}
        featuredImage={
          post.seo?.opengraphImage?.localFile.childImageSharp.gatsbyImageData
        }
      />
      <Banner onClick={onClick} hidden={toggle} />
      {toggle && (
        <ErrorBoundary>
          <MindBodyPage toggleForm={onClick} toggle={toggle} />
        </ErrorBoundary>
      )}
      <div className="post__container">
        <div className="post__container__top d-flex-row">
          <div className="post__main">
            <Hero
              staticImg={HeroImage}
              heroTitle={{ normalTitle: "BLOG" }}
              color="#00edd5"
            />

            <div className="post__header">
              <h1
                className="post__title"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-delay="10"
                data-aos-duration="2000"
              >
                {post?.title}
              </h1>
              <div className="post__desc d-inline-flex">
                <p className="post__date">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="#58ecd6"
                    className="bi bi-calendar4"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v1h14V3a1 1 0 0 0-1-1H2zm13 3H1v9a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V5z" />
                  </svg>
                  {post?.date}
                </p>
                <p className="post__author">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="#58ecd6"
                    className="bi bi-pen"
                    viewBox="0 0 16 16"
                  >
                    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                  </svg>
                  Written by {post?.author?.node?.name}
                </p>
              </div>
            </div>
            <div className="post__content__container">
              {blockJson.map((block, index) => {
                if (block.name === "acf/cta-block") {
                  return (
                    <div className="post__CTA d-flex-row">
                      <div
                        className="post__CTA__left-side"
                        style={{ backgroundImage: `url('${cta1}')` }}
                      >
                        <a
                          href={block?.attributes?.data?.cta_button?.url}
                          className="post__CTA__button"
                          target={block?.attributes?.data?.cta_button?.target}
                        >
                          {block?.attributes?.data?.cta_button?.title}
                        </a>
                        <StaticImage
                          className="dot-line"
                          src="../images/blog-image2.png"
                          alt="arrow"
                        />
                      </div>

                      <div className="post__CTA__right-side d-flex-column">
                        <span></span>
                        <StaticImage
                          className="arrow-line"
                          src="../images/three-arrow.png"
                          alt="arrow"
                        />
                      </div>
                      <div
                        className="post__CTA__content d-flex-column"
                        data-aos="fade-up"
                        data-aos-offset="100"
                        data-aos-delay="10"
                        data-aos-duration="2000"
                      >
                        <h2
                          className="post__CTA__title stroke-text"
                          style={{
                            WebkitTextStrokeColor: `${block?.attributes?.data?.cta_stroke_color}`,
                          }}
                        >
                          {block?.attributes?.data?.cta_stroke_title}
                        </h2>
                        <div
                          className="post__CTA__title normal-text"
                          dangerouslySetInnerHTML={{
                            __html: block?.attributes?.data?.cta_title,
                          }}
                        ></div>
                        <div
                          className="post__CTA__content__content"
                          dangerouslySetInnerHTML={{
                            __html: block?.attributes?.data?.cta_content,
                          }}
                        ></div>
                      </div>
                    </div>
                  )
                }
                if (block.name.includes("image")) {
                  return (
                    <div className="post-content rich-text-edit">
                      <div className="post__excerpt post-image">
                        <img src={block?.attributes?.url} alt="post images" />
                      </div>
                    </div>
                  )
                }
                if (block.name.includes("embed")) {
                  if (block?.attributes?.url.includes("vimeo.com")) {
                    const videodId = block?.attributes?.url
                      .split("/")
                      .slice(-1)[0]
                    return (
                      <div className="post-content rich-text-edit post__embed">
                        <div className="post__excerpt post__youtube">
                          <div>
                            <iframe
                              src={`https://player.vimeo.com/video/${videodId}`}
                              height="300"
                              frameborder="0"
                              allow="autoplay; fullscreen; picture-in-picture"
                              allowfullscreen
                              className="responsive-iframe"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  if (block?.attributes?.url.includes("youtu.be")) {
                    const videodId = block?.attributes?.url
                      .split("/")
                      .slice(-1)[0]
                    return (
                      <div className="post-content rich-text-edit post__embed">
                        <div className="post__excerpt post__youtube">
                          <div>
                            <iframe
                              src={`https://www.youtube.com/embed/${videodId}`}
                              height="400"
                              width="100%"
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                              className="responsive-iframe"
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    )
                  }
                }

                return (
                  <div className="post-content rich-text-edit">
                    <div
                      className="post__excerpt"
                      dangerouslySetInnerHTML={{
                        __html: block?.originalContent,
                      }}
                    />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="post__right-side__container">
            <div className="post__right-side d-flex-row">
              <div className="post__right-side__newletter d-flex-column">
                <h2 className="post__right-side__newletter__title">
                  {formData?.signupTitle}
                </h2>
                <p
                  className={`post__right-side__newletter__subtitle ${pageContext?.lang}`}
                >
                  {formData?.signupSubtitle}
                </p>
                <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                  <input
                    className={`post__right-side__newletter__email ${pageContext?.lang}`}
                    id="email"
                    placeholder={
                      pageContext.lang === "en" ? "Your email" : "Nhập email"
                    }
                    type="email"
                    name="email"
                    value={state.email || ""}
                    required
                    onChange={handleInputChange}
                  />
                  <button
                    type="submit"
                    className={`button post__right-side__newletter__button ${pageContext.lang}`}
                  >
                    {formData?.signupButton}
                  </button>
                </form>
                <div
                  className={`toast-message ${state.toast ? "submited" : ""}`}
                  style={{
                    backgroundColor: `${
                      state.result === "success" ? "green" : "orange"
                    }`,
                  }}
                >
                  <p
                    style={{ color: "white" }}
                    dangerouslySetInnerHTML={{ __html: state?.message }}
                  />
                </div>
              </div>

              <h2 className="post__right-side__categories-title">CATEGORIES</h2>

              <ul className="post__right-side__categories d-flex-row">
                {data?.allWpCategory?.nodes?.map(({ name, slug }, index) => {
                  return (
                    <li key={index} className="post__right-side__category-item">
                      <Link to={`/category/${slug}`}>{name}</Link>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>

        {post?.contactForm?.ctaBlock && (
          <BlogForm blogForm={post?.contactForm} />
        )}
      </div>
    </Layout>
  )
}
export const query = graphql`
  query ($id: String!, $lang: String!) {
    wpPost(id: { eq: $id }) {
      title
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 1200)
            }
          }
        }
      }
      date(formatString: "DD MMMM YYYY")
      content
      blocksJSON
      contactForm {
        backgroundColor
        formDescription
        formTitle
        inputColor
        ctaBlock
      }
      author {
        node {
          avatar {
            url
          }
          name
        }
      }
      tags {
        nodes {
          name
        }
      }
    }
    allWpCategory {
      nodes {
        name
        slug
      }
    }
    wpBlogLanding(
      language: { slug: { eq: $lang } }
      title: { eq: "STAY TUNED" }
    ) {
      blocks {
        ... on WpAcfSignupFormBlock {
          formBlock {
            signupTitle
            signupSubtitle
            signupButton
          }
        }
      }
    }
  }
`
export default BlogPost
